import * as React from "react"

import Layout from "../../components/layout/layout"
import PluginsFeaturedListSectionNew from "../../components/sections/plugins-featured-list-section-new"
import PluginsHeroSectionNew from "../../components/sections/plugins-hero-section-new"
import PluginsShopifyListSectionNew from "../../components/sections/plugins-shopify-list-section-new"
import PluginsSquarespaceListSectionNew from "../../components/sections/plugins-squarespace-list-section-new"
import PluginsWordPressListSectionNew from "../../components/sections/plugins-wordpress-list-section-new"
import Seo from "../../components/seo"

const PluginsPage = () => {
  return (
    <Layout>
      <Seo title="Find The Best Plugins & Apps" />

      <PluginsHeroSectionNew subTitle="SPOTLIGHT" />

      <PluginsFeaturedListSectionNew />

      <PluginsWordPressListSectionNew />

      <PluginsSquarespaceListSectionNew />

      <PluginsShopifyListSectionNew />
    </Layout>
  )
}

export default PluginsPage
