import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PluginsFeaturedListSectionNew = () => {
  const data = useStaticQuery(graphql`
    query PluginsFeaturedListSectionNewQuery {
      prismicPluginsPageFeaturedPluginsApps {
        data {
          wordpress_plugin_name
          wordpress_plugin_price
          wordpress_plugin_link {
            url
          }
          wordpress_plugin_image {
            alt
            gatsbyImageData(
              width: 400
              height: 250
            )
          }
          squarespace_plugin_name
          squarespace_plugin_price
          squarespace_plugin_link {
            url
          }
          squarespace_plugin_image {
            alt
            gatsbyImageData(
              width: 400
              height: 250
            )
          }
          shopify_app_name
          shopify_app_price
          shopify_app_link {
            url
          }
          shopify_app_image {
            alt
            gatsbyImageData(
              width: 400
              height: 250
            )
          }
        }
      }
    }
  `)

  const doc = data.prismicPluginsPageFeaturedPluginsApps
  const wordPressPluginImg = getImage(doc.data.wordpress_plugin_image)
  const squarespacePluginImg = getImage(doc.data.squarespace_plugin_image)
  const shopifyAppImg = getImage(doc.data.shopify_app_image)

  return (
    <div className="bg-gray-100 w-full px-4 pt-0 pb-0 md:pb-24">
      <div className="w-full max-w-screen-xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 xl:gap-12">
          <div className="w-full mb-12 md:mb-0">
            <a href={doc.data.wordpress_plugin_link.url} target='_blank' rel='noopener noreferrer'>
              <GatsbyImage
                image={wordPressPluginImg}
                alt={wordPressPluginImg.alt}
                className="border border-gray-300 hover:border-gray-900"
              />
            </a>

            <p className="text-gray-700 text-xs font-medium uppercase mt-6">WordPress</p>

            <h2 className="text-gray-800 hover:text-gray-900 font-medium text-lg md:text-xl hover:underline mt-2"><a href={doc.data.wordpress_plugin_link.url} target='_blank' rel='noopener noreferrer'>{doc.data.wordpress_plugin_name}</a></h2>

            <p className="text-gray-600 text-md font-medium uppercase mt-2">{doc.data.wordpress_plugin_price}</p>
          </div>

          <div className="w-full mb-12 md:mb-0">
            <a href={doc.data.squarespace_plugin_link.url} target='_blank' rel='noopener noreferrer'>
              <GatsbyImage
                image={squarespacePluginImg}
                alt={squarespacePluginImg.alt}
                className="border border-gray-300 hover:border-gray-900"
              />
            </a>

            <p className="text-gray-700 text-xs font-medium uppercase mt-6">Squarespace</p>

            <h2 className="text-gray-800 hover:text-gray-900 font-medium text-lg md:text-xl hover:underline mt-2"><a href={doc.data.squarespace_plugin_link.url} target='_blank' rel='noopener noreferrer'>{doc.data.squarespace_plugin_name}</a></h2>

            <p className="text-gray-600 text-md font-medium uppercase mt-2">{doc.data.squarespace_plugin_price}</p>
          </div>

          <div className="w-full mb-12 md:mb-0">
            <a href={doc.data.shopify_app_link.url} target='_blank' rel='noopener noreferrer'>
              <GatsbyImage
                image={shopifyAppImg}
                alt={shopifyAppImg.alt}
                className="border border-gray-300 hover:border-gray-900"
              />
            </a>

            <p className="text-gray-700 text-xs font-medium uppercase mt-6">Shopify</p>

            <h2 className="text-gray-800 hover:text-gray-900 font-medium text-lg md:text-xl hover:underline mt-2"><a href={doc.data.shopify_app_link.url} target='_blank' rel='noopener noreferrer'>{doc.data.shopify_app_name}</a></h2>

            <p className="text-gray-600 text-md font-medium uppercase mt-2">{doc.data.shopify_app_price}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PluginsFeaturedListSectionNew