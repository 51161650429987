import React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PluginsShopifyListSectionNew = () => {
  const data = useStaticQuery(graphql`
    query PluginsShopifyListSectionNewQuery {
      allPrismicPluginApp(
        filter: {data: {platform: {eq: "Shopify"}}},
        sort: {fields: data___date_published, order: DESC},
        limit: 3
      ) {
        edges {
          node {
            data {
              date_published
              plugin_app_name
              plugin_app_link {
                url
              }
              platform
              price
              plugin_app_image {
                alt
                gatsbyImageData(
                  width: 400
                  height: 250
                )
              }
            }
          }
        }
      }
    }
  `)

  const doc = data.allPrismicPluginApp.edges

  return (
    <div className="bg-gray-900 w-full px-4 pt-16 pb-4 md:pt-24 md:pb-16">
      <div className="w-full max-w-screen-xl mx-auto">
        <div className="mb-12">
          <Link to="/plugins/shopify/"><h2 className="text-white text-3xl text-center md:text-4xl">Best Shopify Apps</h2></Link>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 xl:gap-12">
          {doc.map(item => {
            const pluginAppImg = getImage(item.node.data.plugin_app_image)

            return (
              <div className="w-full mb-12 md:mb-0">
                <a href={item.node.data.plugin_app_link.url} target='_blank' rel='noopener noreferrer'>
                  <GatsbyImage
                    image={pluginAppImg}
                    alt={pluginAppImg.alt}
                    className="border border-gray-900 hover:border-gray-300"
                  />
                </a>

                <h2 className="text-white font-medium text-lg md:text-xl hover:underline mt-6"><a href={item.node.data.plugin_app_link.url} target='_blank' rel='noopener noreferrer'>{item.node.data.plugin_app_name}</a></h2>

                <p className="text-gray-300 text-md font-medium uppercase mt-2">{item.node.data.price}</p>
              </div>
            )
          })}
        </div>

        <div className="flex items-center justify-center mt-0 md:mt-12 mb-12">
          <Link to="/plugins/shopify/" className="text-white text-lg md:text-xl text-center hover:underline">View All Best Shopify Apps</Link>
        </div>
      </div>
    </div>
  )
}

export default PluginsShopifyListSectionNew